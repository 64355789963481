<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="socialmediareportAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="socialmediareportObjList && socialmediareportObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="socialmediareportObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(post_thumbnail)="data">
                    <div class="text-wrap">
                      <b-button variant="mb-1" title="View Submited Application" size="sm">
                        <img src="../../../assets_gide/img/logo/gide_logo.png" style="width:24px"/>
                      </b-button>
                    </div>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="socialmediareportEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showSocialMediaReportDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSocialMediaReportAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <SocialMediaReportAdd :propOpenedInModal="true" @emitCloseSocialMediaReportAddModal="closeSocialMediaReportAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="socialmediareportAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSocialMediaReportAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSocialMediaReportEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <SocialMediaReportEdit :propOpenedInModal="true" :propSocialMediaReportObj="socialmediareportEditObj" @emitCloseSocialMediaReportEditModal="closeSocialMediaReportEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="socialmediareportEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSocialMediaReportEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSocialMediaReportDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSocialMediaReportDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="socialmediareportDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { SocialMediaReports } from "../../../FackApi/api/socialmediareport.js"
import SocialMediaReportAdd from "./SocialMediaReportAdd"
import SocialMediaReportEdit from "./SocialMediaReportEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "SocialMediaReportList",
  components: {
    SocialMediaReportAdd,
    SocialMediaReportEdit
  },
  data () {
    return {
      cvCardTitle: "Social Media Report",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Social Media Report",
      cvAddModalHeader: "Add Social Media Report",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Social Media Report List Response",
      showModelSocialMediaReportAdd: false,
      showModelSocialMediaReportEdit: false,
      showModelSocialMediaReportDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Creative id", key: "creative_id", class: "text-left align-text-top", sortable: true },
        { label: "Image", key: "post_thumbnail", class: "text-left align-text-top", sortable: true },
        { label: "Module name", key: "module_name", class: "text-left align-text-top", sortable: true },
        { label: "Module id", key: "module_id", class: "text-left align-text-top", sortable: true },
        { label: "Platform", key: "platform", class: "text-left align-text-top", sortable: true },
        { label: "Topic", key: "topic", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Deliverable type", key: "deliverable_type", class: "text-left align-text-top", sortable: true },
        { label: "Reach", key: "reach", class: "text-left align-text-top", sortable: true },
        { label: "Impression", key: "impression", class: "text-left align-text-top", sortable: true },
        { label: "Likes", key: "likes", class: "text-left align-text-top", sortable: true },
        { label: "Saves", key: "saves", class: "text-left align-text-top", sortable: true },
        { label: "Shares", key: "shares", class: "text-left align-text-top", sortable: true },
        { label: "Comments", key: "comments", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Delete On ", key: "modified_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      socialmediareportObjList: null,
      socialmediareportEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null

    }
  },
  mounted () {
    this.socialmediareportList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportList
     */
    async socialmediareportList () {
      try {
        let socialmediareportListResp = await SocialMediaReports.socialmediareportList(this)
        if (socialmediareportListResp.resp_status) {
          this.socialmediareportObjList = socialmediareportListResp.resp_data.data
          this.totalRows = socialmediareportListResp.resp_data.count
        }
        else {
          this.toastMsg = socialmediareportListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at socialmediareportList() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportAdd
     */
    socialmediareportAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/socialmediareport_add")
        }
        else {
          this.showModelSocialMediaReportAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportAdd() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportEdit
     */
    socialmediareportEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/socialmediareport_edit/" + this.socialmediareportEditObj.module_id)
        }
        else {
          this.socialmediareportEditObj = item
          this.showModelSocialMediaReportEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportEdit() and Exception:", err.message)
      }
    },
    /**
     * showSocialMediaReportDeleteDialog
     */
    showSocialMediaReportDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSocialMediaReportDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSocialMediaReportDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportDelete
     */
    async socialmediareportDelete () {
      try {
        let socialmediareportDelResp = await SocialMediaReports.socialmediareportDelete(this, this.delObj.module_id)
        await ApiResponse.responseMessageDisplay(this, socialmediareportDelResp)

        if (socialmediareportDelResp && !socialmediareportDelResp) {
          this.showModelSocialMediaReportDelete = false
          return false
        }

        let index = this.socialmediareportObjList.indexOf(this.delObj)
        this.socialmediareportObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSocialMediaReportDelete = false
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSocialMediaReportAddModal
     */
    closeSocialMediaReportAddModal (socialmediareportAddData) {
      try {
        if (socialmediareportAddData) {
          if (this.socialmediareportObjList && this.socialmediareportObjList.length >= 1) {
            let socialmediareportObjLength = this.socialmediareportObjList.length
            let lastId = this.socialmediareportObjList[socialmediareportObjLength - 1]["id"]
            socialmediareportAddData.id = lastId + 1
          }
          else {
            this.socialmediareportObjList = []
            socialmediareportAddData.id = 11111
          }

          socialmediareportAddData.created_on = moment()
          this.socialmediareportObjList.unshift(socialmediareportAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelSocialMediaReportAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closesocialmediareportAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeSocialMediaReportEditModal
     */
    closeSocialMediaReportEditModal () {
      try {
        this.showModelSocialMediaReportEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSocialMediaReportEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
