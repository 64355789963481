<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#" v-if="vmSocialMediaReportFormData && Object.keys(vmSocialMediaReportFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationreach">
                    {{ cvReachLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.reach" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationimpression">
                    {{ cvImpressionLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.impression" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationlikes">
                    {{ cvLikesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.likes" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsaves">
                    {{ cvSavesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.saves" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationshares">
                    {{ cvSharesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.shares" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcomments">
                    {{ cvCommentsLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.comments" type="text" class="form-control" required />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="socialmediareportEdit()">{{ cvSubmitBtn }}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { SocialMediaReports } from "../../../FackApi/api/socialmediareport"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "SocialMediaReportEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSocialMediaReportObj: {
      type: Object,
      default: function () {
        return {
          "creative_id": "",
          "module_name": "",
          "platform": "",
          "deliverable_type": "",
          "topic": "",
          "reach": "",
          "impression": "",
          "likes": "",
          "saves": "",
          "shares": "",
          "comments": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit socialmediareport",
      cvCardSubHeader: "Edit socialmediareport",
      cvSubmitBtn: "Edit",
      cvTopicLabel: "Topic",
      cvReachLabel: "Reach",
      cvImpressionLabel: "Impression",
      cvLikesLabel: "Likes",
      cvSavesLabel: "Saves",
      cvSharesLabel: "Shares",
      cvCommentsLabel: "Comments",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "SocialMediaReport",
      vmSocialMediaReportFormData: {}
    }
  },
  mounted () {
    this.socialmediareportView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSocialMediaReportFormData) {
          if (!this.vmSocialMediaReportFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * socialmediareportView
     */
    async socialmediareportView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSocialMediaReportFormData = this.propSocialMediaReportObj
        }
        else {
          let moduleId = this.$route.params.module_id
          let socialmediareportViewResp = await SocialMediaReports.socialmediareportView(this, moduleId)

          if (socialmediareportViewResp && socialmediareportViewResp.resp_status) {
            this.vmSocialMediaReportFormData = socialmediareportViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportView() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportEdit
     */
    async socialmediareportEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let socialmediareportAddResp = await SocialMediaReports.socialmediareportEdit(this, this.vmSocialMediaReportFormData)
        await ApiResponse.responseMessageDisplay(this, socialmediareportAddResp)

        if (socialmediareportAddResp && !socialmediareportAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSocialMediaReportEditModal", this.vmSocialMediaReportFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
