/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_id">
                    {{ cvModuleIdLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.module_id" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_name">
                    {{ cvModuleNameLabel }}</label>
                  <multiselect
                    v-model="ModuleName"
                    :options="cvUserTypesOptions"
                    track-by="name"
                    label="name">
                  </multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcreative_id">
                    {{ cvCreativeIdLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.creative_id" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationplatform">
                    {{ cvPlatformLabel }}</label>
                <select class="form-control" v-model="vmSocialMediaReportFormData.platform">
                    <option v-for="(Social_platform, index) of SociaMediaPlatforms" :key="(index+1)" :value="index" >
                    {{ Social_platform }}
                      </option>
                </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdeliverable_type">
                    {{ cvDeliverableTypeLabel }}</label>
                    <select class="form-control" v-model="vmSocialMediaReportFormData.deliverable_type">
                    <option v-for="(DeliverableType, index) of DeliverableType" :key="index+'hosting_platform'" :value="index" >
                      {{ DeliverableType }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationtopic">
                    {{ cvTopicLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.topic" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationreach">
                    {{ cvReachLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.reach" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationimpression">
                    {{ cvImpressionLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.impression" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationlikes">
                    {{ cvLikesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.likes" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsaves">
                    {{ cvSavesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.saves" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationshares">
                    {{ cvSharesLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.shares" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcomments">
                    {{ cvCommentsLabel }}</label>
                  <input v-model="vmSocialMediaReportFormData.comments" type="text" class="form-control" required />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="socialmediareportAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
  .multiselect__select{
    top:5px;
  }
</style>
<script>
import { SocialMediaReports } from "../../../FackApi/api/socialmediareport"
import ApiResponse from "../../../Utils/apiResponse.js"
import SociaMediaPlatforms from "../../../FackApi/json/SocialMediaPlatform.json"
import DeliverableType from "../../../FackApi/json/DeliverableType.json"
import UserRoles from "../../../FackApi/json/UserRoles.json"
import Multiselect from "vue-multiselect"

export default {
  name: "SocialMediaReportAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add socialmediareport",
      cvCardSubHeader: "Add socialmediareport",
      cvSubmitBtn: "Add",
      cvCreativeIdLabel: "Creative id",
      cvModuleIdLabel: "Module id",
      cvModuleNameLabel: "Module name",
      cvPlatformLabel: "Platform",
      cvDeliverableTypeLabel: "Deliverable type",
      cvTopicLabel: "Topic",
      cvReachLabel: "Reach",
      cvImpressionLabel: "Impression",
      cvLikesLabel: "Likes",
      cvSavesLabel: "Saves",
      cvSharesLabel: "Shares",
      cvCommentsLabel: "Comments",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      DeliverableType: DeliverableType,
      SociaMediaPlatforms: SociaMediaPlatforms,
      UserRoles: UserRoles,
      toastTitle: "SocialMediaReport",
      vmSocialMediaReportFormData: Object.assign({}, this.initFormData()),
      vmSocialMediaReportDesc: null,
      vmSocialMediaReportType: null,
      ModuleName: null,
      cvUserTypesOptions: [
        {
          name: "GIDE Admin"
        },
        {
          name: "Educator"
        },
        {
          name: "Parent"
        },
        {
          name: "Student"
        },
        {
          name: "University"
        },
        {
          name: "Organization"
        },
        {
          name: "School"
        }
      ]
    }
  },
  mounted () {
  },
  components: {
    Multiselect
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "creative_id": "",
        "module_name": "",
        "platform": "",
        "deliverable_type": "",
        "topic": "",
        "reach": "",
        "impression": "",
        "likes": "",
        "saves": "",
        "shares": "",
        "comments": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSocialMediaReportFormData) {
          if (!this.vmSocialMediaReportFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * socialmediareportAdd
     */
    async socialmediareportAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        this.vmSocialMediaReportFormData.module_name = this.ModuleName.name
        let socialmediareportAddResp = await SocialMediaReports.socialmediareportAdd(this, this.vmSocialMediaReportFormData)
        await ApiResponse.responseMessageDisplay(this, socialmediareportAddResp)
        if (socialmediareportAddResp && !socialmediareportAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSocialMediaReportAddModal", socialmediareportAddResp.resp_data)
        }
        this.vmSocialMediaReportFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at socialmediareportAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
